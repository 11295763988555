import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Support from "./pages/Support";
import Pricing from "./pages/Pricing";
import Features from "./pages/Features";
import Privacy from "./pages/Privacy";
import ChangeLog from "./pages/ChangeLog";
import Glossary from "./pages/Glossary";
import Terms from "./pages/Terms";
import BlogPost from "./pages/BlogPost";
import BlogShort from "./pages/BlogShort";
import SupportPost from "./pages/SupportPost";
import NotFound from "./pages/404";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/help" element={<Support />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/features" element={<Features />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/changelog" element={<ChangeLog />} />
          <Route path="/glossary" element={<Glossary />} />
          <Route path="/blog/:parama" element={<BlogPost />} />
          <Route path="/a/:short" element={<BlogShort />} />
          <Route path="/help/:parama" element={<SupportPost />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}


export default App;
