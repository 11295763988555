// src/components/Timeline.js
import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import ChatButton from "../components/ChatButton";
import { Helmet } from 'react-helmet-async'; // Correct import
// Changelog data
const changelog = [
	
	{
    version: "1.0.2.6",
    date: "2025 FEB 2",
     description: "Minor Update",
    features: [
       { text: "Project page enhancement", type: "bug-fix" },
],
    fixes: [],
  },

	
	{
    version: "1.0.2.5",
    date: "2025 FEB 1",
     description: "Minor Update",
    features: [
       { text: "Audit Score in Dashboard", type: "new-feature" },
],
    fixes: [],
  },

	
	{
    version: "1.0.2.4",
    date: "2025 JAN 30",
     description: "Major Update",
    features: [
       { text: "Blacklist Monitor", type: "new-feature" },
       { text: "Malicious Scan", type: "new-feature" },
       { text: "Bug Fix", type: "bug-fix" },
    ],
    fixes: [],
  },

	{
    version: "1.0.2.3",
    date: "2025 JAN 7",
     description: "Minor Bug Fixed",
    features: [
       { text: "Bug Fix", type: "bug-fix" },
],
    fixes: [],
  },

	{
    version: "1.0.2.2",
    date: "2025 JAN 6",
     description: "Minor Bug Fixed",
    features: [
       { text: "Bug Fix", type: "bug-fix" },
  ],
    fixes: [],
  },

	{
    version: "1.0.2.1",
    date: "2025 JAN 4",
     description: "Minor Bug Fixed",
    features: [
       { text: "Bug Fix", type: "bug-fix" },
       { text: "Subscription Update", type: "bug-fix" },
       { text: "Backlink Issue", type: "bug-fix" },
    ],
    fixes: [],
  },

	{
    version: "1.0.2",
    date: "2025 JAN 2",
    description: "Major Update",
    features: [
       { text: "AI Code Suggestion", type: "new-feature" },
       { text: "AI Blog Writer", type: "new-feature" },
       { text: "Login Refresh Issue", type: "bug-fix" },
    ],
    fixes: [],
  },

	{
    version: "1.0.1.3",
    date: "2024 DEC 8",
    description: "Minor Bug Fixed",
    features: [
       { text: "Website Audit", type: "bug-fix" },
       { text: "Redirect Issues", type: "bug-fix" },
    ],
    fixes: [],
  },

	{
    version: "1.0.1.2",
    date: "2024 DEC 5",
    description: "Minor Bug Fixed",
    features: [
       { text: "Website Audit", type: "bug-fix" },
       { text: "Redirect Issues", type: "bug-fix" },
    ],
    fixes: [],
  },

	{
    version: "1.0.1.1",
    date: "2024 DEC 1",
    description: "Minor Bug Fixed",
    features: [
      { text: "Empty stages backlinks", type: "bug-fix" },
    ],
    fixes: [],
  },

	{
    version: "1.0.1.0",
    date: "2024 NOV 18",
    description: "Minor Bug Fixed & New Features",
    features: [
       { text: "Guest Post Sites Listing", type: "new-feature" },
      { text: "Ranked Untarget Keywords Monitor", type: "new-feature" },

    ],
    fixes: [],
  },

	{
    version: "1.0.0.3",
    date: "2024 NOV 13",
    description: "Enhancement",
    features: [
      { text: "Cache Control", type: "enhancement" },
    ],
    fixes: [],
  },

  {
    version: "1.0.0.1",
    date: "2024 NOV 12",
    description: "Enhancement",
    features: [
      { text: "Core application functionalities", type: "enhancement" },
    ],
    fixes: [],
  },

  {
    version: "1.0.0",
    date: "2024 NOV 11",
    description: "Initial release",
    features: [
      { text: "Core application functionalities", type: "new-feature" },
      { text: "Basic UI layout", type: "new-feature" },
    ],
    fixes: [],
  },
];

// Helper function to set dot color based on type
const getDotColor = (type) => {
  switch (type) {
    case "new-feature":
      return "bg-green-500"; // New feature
    case "enhancement":
      return "bg-blue-500"; // Enhancement
    case "bug-fix":
      return "bg-red-500"; // Bug fix
    default:
      return "bg-gray-400"; // Default color
  }
};

// Timeline component
const Timeline = () => {
  const [expandedSections, setExpandedSections] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  // Toggle function for each changelog entry
  const toggleSection = (index) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Filter changelog entries based on search term
  const filteredChangelog = changelog.filter((entry) => {
    const term = searchTerm.toLowerCase();
    return (
      entry.version.toLowerCase().includes(term) ||
      entry.description.toLowerCase().includes(term) ||
      entry.features.some((feature) =>
        feature.text.toLowerCase().includes(term)
      ) ||
      entry.fixes.some((fix) => fix.text.toLowerCase().includes(term))
    );
  });

  return (
    <>
     {/* Helmet component to wrap all meta tags and title */}
      <Helmet>
        <title>Seotic Changelog - Powerful SEO Tools for Small Businesses</title>
        <meta
          name="description"
          content="Seotic provides easy to use SEO tools for small businesses to enhance online visibility, drive traffic, and grow your brand. Get started with Seotic today!"
        />
        <meta name="keywords" content="SEO, SEO tools, small business, digital marketing, online visibility, Seotic" />
        <link rel="icon" href="https://www.seotic.co/favicon.ico" />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.seotic.co" />
        <meta property="og:title" content="Seotic - SEO Tools for Small Businesses" />
        <meta
          property="og:description"
          content="Optimize your website's SEO with Seotic's powerful, user friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="og:image" content="https://www.seotic.co/og-image.jpg" />

        {/* Twitter Card Meta Tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.seotic.co" />
        <meta property="twitter:title" content="Seotic - SEO Tools for Small Businesses" />
        <meta
          property="twitter:description"
          content="Optimize your website's SEO with Seotic's powerful, user friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="twitter:image" content="https://www.seotic.co/og-image.jpg" />
      </Helmet>

      <Navbar />
      <section className="container mx-auto p-6">
        <h2 className="text-4xl font-semibold text-center mb-4 mt-10">Changelog</h2>
        <p className="text-center text-gray-600 mb-6">
          Stay updated with our latest improvements, new features, and bug fixes.
          Use the search bar below to find specific updates.
        </p>

        {/* Search Bar */}
        <div className="flex justify-center mb-8">
          <input
            type="text"
            placeholder="Search by version, feature, or fix..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-md w-full max-w-md focus:outline-none"
          />
        </div>

        <div className="relative border-l border-dashed border-gray-300">
        <div className="mb-20 ml-8 relative">
            <span className="absolute -left-9 top-2 flex items-center justify-center w-2 h-2 bg-white ring-2 ring-slate-500 rounded-full"></span>
            <h3 className="text-sm flex items-center font-normal text-slate-700 pt-1 uppercase">
              Up next
            </h3>
          </div>
          {filteredChangelog.length > 0 ? (
            filteredChangelog.map((entry, index) => (
              <div key={index} className="mb-20 ml-8 relative">
                <span className="absolute -left-9 top-3 flex items-center justify-center w-2 h-2 bg-white ring-2 ring-yellow-500 rounded-full"></span>

                <h3 className="mb-1 text-lg flex items-center font-medium text-gray-900">
                  Version {entry.version} <span className="ml-2 text-sm font-normal text-gray-500">{entry.date}</span>
                </h3>
                <p className="text-gray-600 mb-3">{entry.description}</p>

                {/* Show More / Less Toggle */}
                <button
                  onClick={() => toggleSection(index)}
                  className="text-blue-500 hover:text-blue-700 text-sm focus:outline-none"
                >
                  {expandedSections[index] ? "Show Less" : "Show More"}
                </button>

                {/* Features and Fixes Sections */}
                {expandedSections[index] && (
                  <>
                    {/* Features list */}
                    <div className="my-5">
                      <h4 className="font-medium ml-3 text-gray-800">Features</h4>
                      <ul className="list-none space-y-2 mt-1">
                        {entry.features.map((feature, i) => (
                          <li key={i} className="flex items-center">
                            <span
                              className={`w-2 h-2 rounded-full -ml-9 mr-10 ${getDotColor(
                                feature.type
                              )}`}
                            ></span>
                            <span className="text-gray-700">{feature.text}</span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Fixes list */}
                    {entry.fixes.length > 0 && (
                      <div className="mt-3">
                        <h4 className="font-medium ml-3 text-gray-800">Fixes</h4>
                        <ul className="list-none space-y-2 mt-1">
                          {entry.fixes.map((fix, i) => (
                            <li key={i} className="flex items-center">
                              <span
                                className={`w-2 h-2 -ml-9 mr-10 rounded-full ${getDotColor(
                                  fix.type
                                )}`}
                              ></span>
                              <span className="text-gray-700">{fix.text}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500 mt-6">No matching results found.</p>
          )}
        </div>
      </section>
      <ChatButton />
      <Faq />
      <Footer />
    </>
  );
};

export default Timeline;
