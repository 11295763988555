// src/components/SeoGlossary.js
import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import ChatButton from "../components/ChatButton";
import { Helmet } from 'react-helmet-async';

// SEO Glossary Data
const seoGlossary = [
  // A
  { term: "Alt Text", definition: "A description added to an image for accessibility and SEO purposes." },
  { term: "Anchor Text", definition: "The clickable text in a hyperlink that describes the linked page." },
  { term: "Algorithm", definition: "A search engine’s process for ranking web pages based on relevance." },
  { term: "AMP (Accelerated Mobile Pages)", definition: "A Google-backed project to improve mobile page speed." },
  { term: "Authority", definition: "A measure of a website’s credibility in search rankings." },
  { term: "Auto-Generated Content", definition: "Machine-created content, often considered spam by search engines." },

  // B
  { term: "Backlink", definition: "A link from one website to another, important for SEO ranking." },
  { term: "Black Hat SEO", definition: "Unethical SEO techniques that violate search engine guidelines." },
  { term: "Bounce Rate", definition: "The percentage of users who leave a site after viewing one page." },
  { term: "Breadcrumb Navigation", definition: "A navigation aid that helps users track their location within a site." },
  { term: "Broken Link", definition: "A link that leads to a non-existent or deleted page." },
  { term: "Branded Keyword", definition: "A keyword that includes a company’s brand name." },

  // C
  { term: "Canonical URL", definition: "A way to prevent duplicate content by specifying the preferred page URL." },
  { term: "Click-Through Rate (CTR)", definition: "The percentage of users who click on a link in search results." },
  { term: "Cloaking", definition: "A deceptive technique where search engines and users see different content." },
  { term: "Content Marketing", definition: "The practice of creating valuable content to attract and engage audiences." },
  { term: "Conversion Rate", definition: "The percentage of users who complete a desired action on a site." },
  { term: "Core Web Vitals", definition: "Google's user experience metrics including LCP, FID, and CLS." },
  { term: "Crawl Budget", definition: "The number of pages a search engine bot will crawl on a site within a given time." },

  // D
  { term: "Domain Authority", definition: "A ranking score that predicts a website’s ability to rank in search results." },
  { term: "Deep Linking", definition: "The practice of linking to a specific page within a website rather than its homepage." },
  { term: "Duplicate Content", definition: "Content that appears in multiple places, which may hurt SEO rankings." },
  { term: "Dwell Time", definition: "The amount of time a user spends on a page before returning to search results." },
  { term: "Directory Listing", definition: "An online listing of websites categorized by topic or industry." },

  // E
  { term: "Engagement Rate", definition: "A metric that measures user interaction, such as clicks and shares." },
  { term: "E-E-A-T", definition: "Google’s ranking framework: Experience, Expertise, Authority, and Trustworthiness." },
  { term: "External Link", definition: "A link that points to another website outside of the current domain." },
  { term: "Evergreen Content", definition: "Content that remains relevant and valuable over time." },

  // F
  { term: "Featured Snippet", definition: "A brief answer box displayed at the top of Google search results." },
  { term: "Fresh Content", definition: "Regularly updated content that search engines prioritize in rankings." },
  { term: "Footprint", definition: "A pattern that search engines use to identify link-building strategies." },

  // G
  { term: "Google Algorithm Update", definition: "Changes to Google's ranking formula that affect search results." },
  { term: "Googlebot", definition: "Google’s web crawler that scans and indexes web pages." },
  { term: "Guest Posting", definition: "Writing articles for other websites to earn backlinks." },
  { term: "Geotargeting", definition: "Delivering content to users based on their geographic location." },

  // H
  { term: "H1 Tag", definition: "An HTML tag that represents the main heading of a webpage." },
  { term: "HTML Sitemap", definition: "A human-readable sitemap that lists all pages on a site." },
  { term: "Hyperlink", definition: "A clickable link that connects one webpage to another." },

  // I
  { term: "Indexing", definition: "The process of storing and organizing web pages in a search engine’s database." },
  { term: "Internal Linking", definition: "Links that connect pages within the same website." },
  { term: "Image Optimization", definition: "The process of optimizing images to improve page load speed and SEO." },

  // J
  { term: "JavaScript SEO", definition: "Optimizing JavaScript-heavy sites for search engine indexing." },
  { term: "JSON-LD", definition: "A structured data format used for SEO and rich snippets." },

  // K
  { term: "Keyword", definition: "A word or phrase that users search for on search engines." },
  { term: "Keyword Density", definition: "The percentage of times a keyword appears in content." },
  { term: "Knowledge Graph", definition: "Google’s database that provides direct answers in search results." },

  // L
  { term: "Link Building", definition: "The practice of acquiring backlinks to improve SEO rankings." },
  { term: "Long-Tail Keyword", definition: "A highly specific, multi-word search phrase." },
  { term: "Local SEO", definition: "Optimizing a website for location-based searches." },

  // M
  { term: "Meta Description", definition: "A short summary of a webpage displayed in search results." },
  { term: "Mobile Optimization", definition: "SEO strategies for improving a website’s performance on mobile devices." },

  // N
  { term: "Nofollow Link", definition: "A link that tells search engines not to pass ranking value to the destination page." },
  { term: "Negative SEO", definition: "Malicious SEO tactics designed to harm a competitor’s rankings." },

  // O
  { term: "Organic Traffic", definition: "Visitors who arrive at a website through unpaid search results." },
  { term: "On-Page SEO", definition: "Optimizing elements on a webpage to improve rankings." },

  // P
  { term: "PageSpeed", definition: "The speed at which a webpage loads, affecting SEO rankings." },
  { term: "Penalty", definition: "A ranking drop due to violations of search engine guidelines." },

  // Q
  { term: "Query Intent", definition: "The purpose behind a user's search query." },

  // R
  { term: "RankBrain", definition: "Google’s AI algorithm for processing search queries." },
  { term: "Redirect", definition: "A technique that forwards users from one URL to another." },

  // S
  { term: "Schema Markup", definition: "A structured data format that helps search engines understand content." },
  { term: "SERP", definition: "Search Engine Results Page, displaying results for a given search." },

  // T
  { term: "Title Tag", definition: "An HTML element specifying a webpage's title." },
  { term: "Technical SEO", definition: "SEO that focuses on site structure, speed, and crawlability." },

  // U
  { term: "User Experience (UX)", definition: "A user’s interaction with and perception of a website." },

  // V
  { term: "Voice Search Optimization", definition: "SEO techniques for voice-based searches." },

  // W
  { term: "White Hat SEO", definition: "Ethical SEO techniques that follow search engine guidelines." },

  // X
  { term: "XML Sitemap", definition: "A file that lists all pages on a website to aid search engines." },

  // Y
  { term: "YouTube SEO", definition: "Optimizing videos for better rankings on YouTube and Google." },

  // Z
  { term: "Zero-Click Searches", definition: "Searches where users find answers directly on the results page." }
];


const groupByAlphabet = (data) => {
  return data.reduce((acc, entry) => {
    const firstLetter = entry.term[0].toUpperCase();
    if (!acc[firstLetter]) acc[firstLetter] = [];
    acc[firstLetter].push(entry);
    return acc;
  }, {});
};

const SeoGlossary = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredGlossary = seoGlossary.filter((entry) =>
    entry.term.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupedGlossary = groupByAlphabet(filteredGlossary);

  return (
    <>
      <Helmet>
        <title>SEO Glossary - Essential SEO Terms Explained</title>
        <meta name="description" content="Learn essential SEO terms and definitions to improve your search engine optimization knowledge." />
        <meta name="keywords" content="SEO, SEO glossary, search engine optimization, digital marketing terms" />
      </Helmet>
      <Navbar />
      <section className="container mx-auto mb-10 p-2 md:p-0">
        <h2 className="text-4xl font-semibold text-center mb-4 mt-10">SEO Glossary</h2>
        <p className="text-center text-gray-600 mb-6">
          Explore key SEO terms and their definitions to improve your understanding of search engine optimization.
        </p>

        {/* Search Bar */}
        <div className="flex justify-center mb-8">
          <input
            type="text"
            placeholder="Search SEO term..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-md w-full max-w-md focus:outline-none"
          />
        </div>

        {/* Alphabet Navigation */}
        <div className="hidden md:block fixed top-20 right-5 space-y-2 bg-white p-2 border rounded-lg text-center">
          {Object.keys(groupedGlossary).map(letter => (
            <a key={letter} href={`#${letter}`} className="block text-yellow-500 hover:text-yellow-800">{letter}</a>
          ))}
        </div>

        {/* Glossary List */}
        <div className="space-y-4">
          {Object.keys(groupedGlossary).length > 0 ? (
            Object.keys(groupedGlossary).map(letter => (
              <div key={letter} id={letter} className="mt-8">
                <h2 className="text-lg font-normal text-gray-500">{letter}</h2>
                {groupedGlossary[letter].map((entry, index) => (
                  <div key={index} className="p-4 border border-gray-200 rounded-lg mt-2">
                    <h3 className="text-lg font-bold text-gray-900">{entry.term}</h3>
                    <p className="text-gray-700">{entry.definition}</p>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No matching terms found.</p>
          )}
        </div>
      </section>
      <ChatButton />
      <Faq />
      <Footer />
    </>
  );
};

export default SeoGlossary;
