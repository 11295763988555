// src/components/Footer.js
import React from "react";

function Footer() {
	const currentYear = new Date().getFullYear();
  return (
    <footer  className="bg-black text-black">

<div className="bg-yellow-50 md:flex hidden">

<div className="flex items-center container mx-auto py-24">
<div className=" text-3xl uppercase text-medium">
Power up your SEO strategy and grow your online presence effortlessly.
</div>

<div className="justify-end flex items-center w-full text-md">
 <p className="mr-10 text-yellow-900">Forever FREE. No credit card needed!</p>
<a href="https://app.seotic.co/register" target="_blank" title="Create Account" className="bg-slate-900 text-white rounded px-5 py-3 ">Create Free Account</a>
</div>

</div>

</div>



        <div
             className="container flex flex-col flex-wrap px-5 py-24 mx-auto md:items-center lg:items-start md:flex-row md:flex-no-wrap text-white">
            <div  className="flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left">
              <div className="flex md:block justify-center">
        <img className="w-28" src="/seotic-logo-w.png" alt="Seotic Logo" />
      </div>

                <p  className="mt-2 text-sm text-white">Grow Your Rank, Green the Earth</p>
                <div  className="mt-4">
                    <span  className="inline-flex justify-center mt-2 sm:ml-auto sm:mt-0 sm:justify-start">
                        <a href="https://www.facebook.com/seotic.co" target="_blank" title="Seotic's Facebook" className="text-white cursor-pointer hover:text-slate-200">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                 className="w-5 h-5" viewBox="0 0 24 24">
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                            </svg>
                        </a>
                                             <a  href="https://www.instagram.com/seotic.co" target="_blank" title="Seotic's Instagram" className="ml-3 text-white cursor-pointer hover:text-slate-200">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2"  className="w-5 h-5" viewBox="0 0 24 24">
                                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                            </svg>
                        </a>
                        <a  href="https://www.linkedin.com/company/getseotic/" target="_blank" title="Seotic's Linkedin" className="ml-3 text-white cursor-pointer hover:text-slate-200">
                            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="0"  className="w-5 h-5" viewBox="0 0 24 24">
                                <path stroke="none"
                                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z">
                                </path>
                                <circle cx="4" cy="4" r="2" stroke="none"></circle>
                            </svg>
                        </a>
                    </span>
                </div>
            </div>
            <div  className="flex flex-wrap flex-grow mt-10 -mb-10 text-center md:pl-20 md:mt-0 md:text-left">
                <div  className="w-full px-4 lg:w-1/4 md:w-1/2">
                    <h2  className="mb-3 text-sm font-medium tracking-widest text-white uppercase title-font">Company</h2>
                    <nav  className="mb-10 list-none">
                        <li  className="mt-3">
                            <a  className="text-gray-500 cursor-pointer hover:text-white">About Us</a>
                        </li>
                        <li  className="mt-3">
                            <a href="/privacy" title="Privacy and Policy"  className="text-gray-500 cursor-pointer hover:text-white">Privacy Policy</a>
                        </li>
                    
                        <li  className="mt-3">
                            <a  href="/terms" title="Terms and Conditions" className="text-gray-500 cursor-pointer hover:text-white">Terms &amp; Condition</a>
                        </li>
                    </nav>
                </div>
                <div  className="w-full px-4 lg:w-1/4 md:w-1/2">
                    <h2  className="mb-3 text-sm font-medium tracking-widest text-white uppercase title-font">Support</h2>
                    <nav  className="mb-10 list-none">
                        <li  className="mt-3">
                            <a  href="/help" title="Support" className="text-gray-500 cursor-pointer hover:text-white">Contact Support</a>
                        </li>
                       
                        <li  className="mt-3">
                            <a  href="/changelog" title="Change Log" className="text-gray-500 cursor-pointer hover:text-white">Change Logs</a>
                        </li>
                         <li  className="mt-3">
                            <a href="https://status.seotic.co" target="_blank" title="Status Page" className="text-gray-500 cursor-pointer hover:text-white">Status Page</a>
                        </li>

                    </nav>
                </div>
                <div  className="w-full px-4 lg:w-1/4 md:w-1/2">
                    <h2  className="mb-3 text-sm font-medium tracking-widest text-white uppercase title-font">Resources
                    </h2>
                    <nav  className="mb-10 list-none">
                        <li  className="mt-3">
                            <a  className="text-gray-500 cursor-pointer hover:text-white">Academy <span className="text-xs w-fit bg-green-500 text-white rounded px-2 py-1 ml-2">soon</span></a>
                        </li>
                        <li  className="mt-3">
                            <a href="/blog" title="Seotic Blog" className="text-gray-500 cursor-pointer hover:text-white">Seotic Blog</a>
                        </li>
                        <li  className="mt-3">
                            <a href="/glossary" className="text-gray-500 cursor-pointer hover:text-white">SEO Glossary</a>
                        </li>
                    
                    </nav>
                </div>
                <div  className="w-full px-4 lg:w-1/4 md:w-1/2">
                    <h2  className="mb-3 text-sm font-medium tracking-widest text-white uppercase title-font">Contact</h2>
                    <nav  className="mb-10 list-none">
                        <li  className="mt-3">
                            <a href="mailto:hello@seotic.co" className="text-gray-500 cursor-pointer hover:text-white">Send a Message</a>
                        </li>
                        <li  className="mt-3">
                            <a  href="https://request.seotic.co" target="_blank" title="Feature Request"  className="text-gray-500 cursor-pointer hover:text-white">Feature Requests</a>
                        </li>
                        <li  className="mt-3">
                            <a  className="text-gray-500 cursor-pointer hover:text-white">+66 660 013 943</a>
                        </li>
                    </nav>
                </div>
            </div>
        </div>
        <div  className="bg-black border-t border-slate-900">
            <div  className="container px-5 py-4 mx-auto">
                <p  className="text-sm text-white capitalize text-center">© Seotic {currentYear} </p>
            </div>
        </div>
    </footer>

  );
}

export default Footer;
